import Choices from "choices.js";

import "jquery-validation-unobtrusive";

window.addEventListener("load", () => {
	document.querySelectorAll(".choices-single").forEach(el => {
		new Choices(el,
			{
				searchPlaceholderValue: "Search",
				itemSelectText: "",
				noResultsText: "No results",
				searchResultLimit: 15,
				searchFields: ["label"],
			});
	});
})

